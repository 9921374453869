import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { OutlineButton, blackAt62, fonts } from '@lumoslabs/react_lux';

// utils
import i18n from '../../utils/i18n';
import ScrollReveal from '../utils/ScrollRevealWrapper';

// styles
import * as layout from '../../styles/helpers/layout';
import * as buttons from '../../styles/helpers/buttons';
import scienceModulesLayout from './layout_helper';

// images
import crosswordStudy from './images/crossword_study.svg';

const styles = StyleSheet.create({
  container: {
    paddingTop: 40
  },
  longParagraphCol: {
    [layout.mediaQuery.minWidth.mobileLarge]: {
      paddingLeft: 0
    }
  },
  footnote: {
    ...fonts.smallCaption,
    marginTop: 40,
    marginBottom: 40,
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginTop: 24,
      marginBottom: 24
    }
  },
  cta: {
    ...buttons.outlineButtonDefaults
  },
  ctaCol: {
    textAlign: 'center',
    ...layout.centerHorizontal,
    flexDirection: 'column'
  },
  extendedCta: {
    ...buttons.extendedButtonDefaults
  },
  centeringRow: {
    ...layout.centerHorizontal
  },
  imageContainer: {
    marginTop: 48,
    marginBottom: 24,
    width: 1128,
    maxWidth: '90vw',
    boxSizing: 'border-box',
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginTop: 24,
      marginBottom: 16,
      maxWidth: '100vw',
      overflowX: 'hidden'
    }
  },
  captionCol: {
    [layout.mediaQuery.minWidth.tablet]: {
      paddingLeft: 0
    }
  },
  caption: {
    ...fonts.smallCaption,
    textAlign: 'center',
    color: blackAt62
  }
});

function EfficacyStudy() {
  return (
    <Container className={css(scienceModulesLayout.container, styles.container)} id="efficacy-study">
      <Row>
        <Col xs="12" className={css(scienceModulesLayout.supraheaderCol)}>
          <h5 className={css(scienceModulesLayout.supraheader)}>{i18n.t('SciencePage.EfficacyStudy.supraheader')}</h5>
        </Col>
        <Col xs="12" className={css(scienceModulesLayout.headerCol)}>
          <h3 className={css(scienceModulesLayout.header)}>{i18n.t('SciencePage.EfficacyStudy.header')}</h3>
        </Col>
        <Col xs="12" className={css(styles.longParagraphCol)}>
          <p className={css(scienceModulesLayout.paragraph)}>{i18n.t('SciencePage.EfficacyStudy.detailsFirstParagraph')}</p>
          <br />
          <p className={css(scienceModulesLayout.paragraph)}>{i18n.t('SciencePage.EfficacyStudy.detailsSecondParagraph')}</p>
          <p className={css(styles.footnote)}>{i18n.t('SciencePage.EfficacyStudy.footnote')}</p>
        </Col>
        <Col xs="12" className={css(styles.ctaCol)}>
          <ScrollReveal animation="fadeIn">
            <OutlineButton
              href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0134467"
              styles={[styles.cta, styles.extendedCta]}
              track="true"
              trackButtonPress="science_research_publications"
              trackLocation="body"
            >
              {i18n.t('SciencePage.EfficacyStudy.readMore')}
            </OutlineButton>
          </ScrollReveal>
        </Col>
      </Row>
      <Row className={css(styles.centeringRow)}>
        <Col xs="12" className={css(styles.imageContainer)}>
          <ScrollReveal animation="expand">
            <img
              src={crosswordStudy}
              className={css(styles.image)}
              alt={i18n.t('SciencePage.EfficacyStudy.imageAltText')}
            />
          </ScrollReveal>
        </Col>
        <Col xs="12" className={css(styles.captionCol)}>
          <p className={css(styles.caption)}>{i18n.t('SciencePage.EfficacyStudy.imageCaption')}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default EfficacyStudy;
