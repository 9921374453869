import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { plum7, whiteAt98, fonts } from '@lumoslabs/react_lux';

// utils
import i18n from '../../utils/i18n';

// style assets
import * as layout from '../../styles/helpers/layout';

const styles = StyleSheet.create({
  stepsContainer: {
    marginTop: 50,
    marginBottom: 72,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginTop: 40,
      marginBottom: 40
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      flexDirect: 'column'
    }
  },
  stepContainer: {
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      ...layout.centerVertical,
      ...layout.centerHorizontal,
      flexDirection: 'column'
    }
  },
  enumeratorWrapper: {
    ...layout.centerHorizontal,
    ...layout.centerVertical,
    height: 40,
    width: 40,
    backgroundColor: plum7,
    borderRadius: '50%',
    [layout.mediaQuery.maxWidth.tablet]: {
      height: 36,
      width: 36
    }
  },
  enumerator: {
    ...fonts.p,
    color: whiteAt98,
    fontWeight: 700,
    textAlign: 'center'
  },
  designProcessHeader: {
    ...fonts.h5,
    marginTop: 8,
    marginBottom: 8,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginTop: 6,
      marginBottom: 6
    }
  },
  designProcessDetail: {
    ...fonts.p,
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginBottom: 28,
      textAlign: 'center'
    }
  }
});

function DesignStepGroup() {
  return (
    <Row className={css(styles.stepsContainer)}>
      <Col xs="12" md="4" className={css(styles.stepContainer)}>
        <div className={css(styles.enumeratorWrapper)}>
          <p className={css(styles.enumerator)}>1</p>
        </div>
        <h5 className={css(styles.designProcessHeader)}>
          {i18n.t('SciencePage.GameDesign.designProcess.research.header')}
        </h5>
        <p className={css(styles.designProcessDetail)}>
          {i18n.t('SciencePage.GameDesign.designProcess.research.detail')}
        </p>
      </Col>
      <Col xs="12" md="4" className={css(styles.stepContainer)}>
        <div className={css(styles.enumeratorWrapper)}>
          <p className={css(styles.enumerator)}>2</p>
        </div>
        <h5 className={css(styles.designProcessHeader)}>
          {i18n.t('SciencePage.GameDesign.designProcess.design.header')}
        </h5>
        <p className={css(styles.designProcessDetail)}>
          {i18n.t('SciencePage.GameDesign.designProcess.design.detail')}
        </p>
      </Col>
      <Col xs="12" md="4" className={css(styles.stepContainer)}>
        <div className={css(styles.enumeratorWrapper)}>
          <p className={css(styles.enumerator)}>3</p>
        </div>
        <h5 className={css(styles.designProcessHeader)}>
          {i18n.t('SciencePage.GameDesign.designProcess.build.header')}
        </h5>
        <p className={css(styles.designProcessDetail)}>{i18n.t('SciencePage.GameDesign.designProcess.build.detail')}</p>
      </Col>
    </Row>
  );
}

export default DesignStepGroup;
