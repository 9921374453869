import React from 'react';
import { JSONLD, Author, Generic } from 'react-structured-data';

function SchemaDotOrg() {
  return (
    <JSONLD dangerouslyExposeHtml>
      <Generic
        type="article"
        jsonldtype="Article"
        schema={{
          headline: 'Brain training built on science',
          image: [
            'https://asset.lumosity.com/resources/static/crossword_study.png',
            'https://asset.lumosity.com/resources/static/game_design.png',
            'https://asset.lumosity.com/resources/static/research_partners.png'
          ],
          keywords: 'brain training, cognitive research, brain workout',
          url: 'https://www.lumosity.com/brain-training',
          datePublished: '2018-11-21',
          dateCreated: '2018-11-21',
          dateModified: '2018-11-21',
          description:
            'Our in-house Science team is committed to translating cognitive science into accessible brain training',
          articleBody:
            'There have been over 20 peer-reviewed publications in academic journals using Lumosity games or assessments. In one study, our scientists conducted a randomized trial involving 4,715 participants in order to study whether cognitive performance improves after training with Lumosity. The test group trained with Lumosity, while the control group trained using crossword puzzles. Both groups trained five days per week, for fifteen minutes a day.  There have been over 20 peer-reviewed publications in academic journals using Lumosity games or assessments. In one study, our scientists conducted a randomized trial involving 4,715 participants in order to study whether cognitive performance improves after training with Lumosity. The test group trained with Lumosity, while the control group trained using crossword puzzles. Both groups trained five days per week, for fifteen minutes a day.'
        }}
      >
        <Author name="Lumosity Scientists" />
        <Generic
          type="mainEntityOfPage"
          jsonldtype="MainEntityOfPage"
          schema={{ '@type': 'WebPage', '@id': 'https://www.lumosity.com/brain-training' }}
        />
        <Generic
          type="publisher"
          jsonldtype="Publisher"
          schema={{
            '@type': 'Organization',
            name: 'Lumosity'
          }}
        >
          <Generic
            type="logo"
            jsonldtype="Logo"
            schema={{
              '@type': 'ImageObject',
              url: 'https://asset.lumosity.com/resources/static/lumosity_logo.png'
            }}
          />
        </Generic>
      </Generic>
    </JSONLD>
  );
}

export default SchemaDotOrg;
