import React from 'react';
import PropTypes from 'prop-types';
import { plum7 } from '@lumoslabs/react_lux';

// utils
import i18n from 'utils/i18n';
import Layout from 'components/layout';
import Metatags from 'components/Metatags';

import {
  EclipseHero,
  EfficacyStudy,
  GameDesign,
  ResearchPartners,
  JumboCTA,
  SchemaDotOrg
} from '../components/SciencePage';

function SciencePageIndex({ location, pageContext }) {
  const { locale, pagePath } = pageContext;
  return (
    <Layout locale={pageContext.locale} location={location} isStickyHeader accentColor={plum7}>
      <Metatags
        title={i18n.t('SciencePage.title')}
        description={i18n.t('SciencePage.description')}
        type="article"
        canonical={pagePath}
        locale={locale}
      />
      <EclipseHero />
      <EfficacyStudy />
      <GameDesign />
      <ResearchPartners />
      <JumboCTA />
      <SchemaDotOrg />
    </Layout>
  );
}

SciencePageIndex.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    pagePath: PropTypes.string.isRequired
  })
};

SciencePageIndex.defaultProps = {
  pageContext: {
    locale: 'en'
  }
};

export default SciencePageIndex;
