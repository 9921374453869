import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';

// utils
import i18n from 'utils/i18n';

// style assets
import * as layout from 'styles/helpers/layout';
import { whiteAt98, fonts } from '@lumoslabs/react_lux';

// util component
import HeroWrapper from 'components/ui/layout/HeroWrapper';

// image assets
import background from './images/eclipse_hero_background.svg';

// child components
import AnchorLinkGroup from './AnchorLinkGroup';

const styles = StyleSheet.create({
  centeringRow: {
    ...layout.centerHorizontal
  },
  headerCol: {
    ...layout.centerHorizontal
  },
  header: {
    ...fonts.h1,
    fontWeight: 700,
    color: whiteAt98,
    textAlign: 'center'
  },
  subheaderCol: {
    ...layout.centerHorizontal,
    marginTop: 20,
    marginBottom: 40,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginTop: 30,
      marginBottom: 32
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginTop: 12,
      marginBottom: 24
    }
  },
  subheader: {
    ...fonts.p,
    color: whiteAt98,
    textAlign: 'center'
  }
});

const backgroundStyles = StyleSheet.create({
  background: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: 522,
    textAlign: 'center'
  }
});

function EclipseHero({ locale }) {
  return (
    <HeroWrapper backgroundStyles={backgroundStyles.background}>
      <Container fluid className={css(styles.container)}>
        <Row className={css(styles.centeringRow)}>
          <Col xs="12" md="10" lg="7" xl="9" className={css(styles.headerCol)}>
            <h1 className={css(styles.header)}>{i18n.t('SciencePage.EclipseHero.header')}</h1>
          </Col>
          <Col xs="12" md="10" lg="8" className={css(styles.subheaderCol)}>
            <p className={css(styles.subheader)}>{i18n.t('SciencePage.EclipseHero.subheader')}</p>
          </Col>
          <AnchorLinkGroup locale={locale} />
        </Row>
      </Container>
    </HeroWrapper>
  );
}

EclipseHero.propTypes = {
  locale: PropTypes.string
};

EclipseHero.defaultProps = {
  locale: 'en'
};

export default EclipseHero;
