export default function slideToAnchor(tagId, offset) {
  const target = document.getElementById(tagId);

  const targetPosition = target.getBoundingClientRect().top;
  const offsetPosition = targetPosition - offset;

  const isIE11Client = !!window.MSInputMethodContext && !!document.documentMode;

  if (isIE11Client) {
    window.scrollBy(0, offsetPosition);
  } else {
    window.scrollBy({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}
