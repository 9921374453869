import React from 'react';
import { RoundedButton, blackAt62, fonts } from '@lumoslabs/react_lux';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';

// utils
import i18n from '../../utils/i18n';
import ScrollReveal from '../utils/ScrollRevealWrapper';

// styles
import * as layout from '../../styles/helpers/layout';
import * as buttons from '../../styles/helpers/buttons';
import scienceModulesLayout from './layout_helper';

// images
import gameDesignImage from './images/game_design.svg';

// child components
import DesignStepGroup from './DesignStepGroup';

const styles = StyleSheet.create({
  imageContainer: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    ...layout.centerVertical,
    ...layout.centerHorizontal,
    flexDirection: 'column'
  },
  image: {
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      width: '110%'
    }
  },
  captionCol: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    width: '80%',
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginTop: 0,
      marginBottom: 0
    }
  },
  caption: {
    ...fonts.smallCaption,
    color: blackAt62
  },
  cta: {
    ...buttons.buttonDefaults
  }
});

function GameDesign() {
  return (
    <Container className={css(scienceModulesLayout.container)} id="game-design">
      <Row className={css(scienceModulesLayout.centeringRow)}>
        <Col xs="12" className={css(scienceModulesLayout.supraheaderCol)}>
          <h5 className={css(scienceModulesLayout.supraheader)}>{i18n.t('SciencePage.GameDesign.supraheader')}</h5>
        </Col>
        <Col xs="12" md="8" className={css(scienceModulesLayout.headerCol)}>
          <h3 className={css(scienceModulesLayout.header)}>{i18n.t('SciencePage.GameDesign.header')}</h3>
        </Col>
      </Row>
      <Row className={css(scienceModulesLayout.centeringRow)}>
        <Col xs="12" className={css(styles.imageContainer)}>
          <ScrollReveal animation="expand">
            <img src={gameDesignImage} alt={i18n.t('SciencePage.GameDesign.imageAltText')} />
          </ScrollReveal>
        </Col>
        <Col xs="12" className={css(styles.captionCol)}>
          <p className={css(styles.caption)}>{i18n.t('SciencePage.GameDesign.imageCaption')}</p>
        </Col>
      </Row>
      <DesignStepGroup />
      <Row className={css(scienceModulesLayout.centeringRow)}>
        <ScrollReveal animation="fadeIn">
          <Col xs="12" className={css(scienceModulesLayout.centeringRow)}>
            <RoundedButton
              href="/sign_up"
              styles={[styles.cta]}
              track="true"
              trackButtonPress="science_start_training"
              trackLocation="body"
            >
              {i18n.t('SciencePage.GameDesign.startTraining')}
            </RoundedButton>
          </Col>
        </ScrollReveal>
      </Row>
    </Container>
  );
}

export default GameDesign;
