import { blackAt62, fonts } from '@lumoslabs/react_lux';
import { StyleSheet } from 'aphrodite';
import * as layout from '../../styles/helpers/layout';

const scienceModulesLayout = StyleSheet.create({
  container: {
    ...layout.defaultContainerProperties
  },
  supraheaderCol: {
    ...layout.centerHorizontal
  },
  supraheader: {
    ...fonts.h5,
    color: blackAt62
  },
  headerCol: {
    ...layout.centerHorizontal,
    textAlign: 'center',
    marginBottom: 24,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginBottom: 20
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginBottom: 16
    }
  },
  header: {
    ...fonts.h3
  },
  centeringRow: {
    ...layout.centerHorizontal
  },
  paragraph: {
    ...fonts.p
  }
});

export default scienceModulesLayout;
