import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';
import { lumosBlue, fonts } from '@lumoslabs/react_lux';

// utils
import i18n from '../../utils/i18n';
import ScrollReveal from '../utils/ScrollRevealWrapper';

// styles
import * as layout from '../../styles/helpers/layout';
import scienceModulesLayout from './layout_helper';

// images
import ResearchPartnersImage from './images/research_partners.svg';

const styles = StyleSheet.create({
  paragraphCol: {
    textAlign: 'center',
    ...layout.centerHorizontal,
    flexDirection: 'column'
  },
  imageCol: {
    ...layout.centerHorizontal
  },
  image: {
    marginTop: 72,
    marginBottom: 72,
    width: '100%',
    height: 'auto',
    maxWidth: '80vw',
    ...layout.centerVertical,
    ...layout.centerHorizontal,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginTop: 40,
      marginBottom: 40
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      maxWidth: '90vw'
    }
  },
  ctaText: {
    ...fonts.h5,
    textAlign: 'center'
  },
  linkCol: {
    ...layout.centerHorizontal,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 72,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginBottom: 20
    },
    [layout.mediaQuery.maxWidth.tablet]: {
      marginBottom: 15
    }
  },
  link: {
    ...fonts.h5,
    textDecoration: 'underline',
    color: lumosBlue
  }
});

function ResearchPartners() {
  return (
    <Container className={css(scienceModulesLayout.container)} id="research-partners">
      <Row>
        <Col xs="12" className={css(scienceModulesLayout.supraheaderCol)}>
          <h5 className={css(scienceModulesLayout.supraheader)}>{i18n.t('SciencePage.ResearchPartners.supraheader')}</h5>
        </Col>
        <Col xs="12" className={css(scienceModulesLayout.headerCol)}>
          <h3 className={css(scienceModulesLayout.header)}>{i18n.t('SciencePage.ResearchPartners.header')}</h3>
        </Col>
      </Row>
      <Row className={css(scienceModulesLayout.centeringRow)}>
        <Col xs="12" lg="10" className={css(styles.paragraphCol)}>
          <p className={css(scienceModulesLayout.paragraph)}>
            {i18n.t('SciencePage.ResearchPartners.detailsFirstParagraph')}
          </p>
          <br />
          <p className={css(scienceModulesLayout.paragraph)}>
            {i18n.t('SciencePage.ResearchPartners.detailsSecondParagraph')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className={css(styles.imageCol)}>
          <ScrollReveal animation="expand">
            <img
              src={ResearchPartnersImage}
              alt={i18n.t('SciencePage.ResearchPartners.imageAltText')}
              className={css(styles.image)}
            />
          </ScrollReveal>
        </Col>
        <Col xs="12">
          <h5 className={css(styles.ctaText)}>{i18n.t('SciencePage.ResearchPartners.collaborate')}</h5>
        </Col>
        <Col xs="12" className={css(styles.linkCol)}>
          <a
            href="/hcp"
            className={css(styles.link)}
            data-track
            data-track-link-click="science_researchers_apply"
            data-track-location="body"
          >
            {i18n.t('SciencePage.ResearchPartners.learnMore')}
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default ResearchPartners;
