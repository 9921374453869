import React from 'react';
import { StyleSheet, css } from 'aphrodite';

// style assets
import * as layout from '../../../styles/helpers/layout';

// child components
import AnchorLink from './AnchorLink';

const styles = StyleSheet.create({
  linkGroup: {
    ...layout.centerHorizontal,
    ...layout.centerVertical,
    minWidth: '100%',
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      flexDirection: 'column'
    }
  }
});

function AnchorLinkGroup() {
  return (
    <div className={css(styles.linkGroup)}>
      <AnchorLink linkTarget="efficacy-study" />
      <AnchorLink linkTarget="game-design" />
      <AnchorLink linkTarget="research-partners" />
    </div>
  );
}

export default AnchorLinkGroup;
