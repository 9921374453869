import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { RoundedButton, plum7, whiteAt98, fonts } from '@lumoslabs/react_lux';

// utils
import i18n from '../../utils/i18n';
import ScrollReveal from '../utils/ScrollRevealWrapper';

// styles
import * as layout from '../../styles/helpers/layout';
import * as buttons from '../../styles/helpers/buttons';

const styles = StyleSheet.create({
  heightWrapper: {
    ...layout.centerHorizontal,
    ...layout.centerVertical,
    backgroundColor: plum7,
    height: 224,
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      height: 199
    }
  },
  container: {
    ...layout.defaultContainerProperties
  },
  centeringRow: {
    ...layout.centerHorizontal
  },
  header: {
    ...fonts.h3,
    textAlign: 'center',
    color: whiteAt98,
    marginBottom: 12
  },
  cta: {
    ...buttons.buttonDefaults
  }
});

function JumboCta() {
  return (
    <div className={css(styles.heightWrapper)}>
      <Container fluid className={css(styles.container)}>
        <Row className={css(styles.centeringRow)}>
          <Col xs="12">
            <h3 className={css(styles.header)}>{i18n.t('SciencePage.JumboCTA.header')}</h3>
          </Col>
          <ScrollReveal animation="fadeIn">
            <Col xs="12" className={css(styles.centeringRow)}>
              <RoundedButton
                href="/sign_up"
                styles={[styles.cta]}
                track="true"
                trackButtonPress="science_train_now"
                trackLocation="body"
              >
                {i18n.t('SciencePage.JumboCTA.trainNow')}
              </RoundedButton>
            </Col>
          </ScrollReveal>
        </Row>
      </Container>
    </div>
  );
}

export default JumboCta;
