import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

// utils
import i18n from 'utils/i18n';
import slideToAnchor from 'utils/slideToAnchor';

// style assets
import * as layout from 'styles/helpers/layout';
import { whiteAt98, fonts } from '@lumoslabs/react_lux';

const headerOffset = 68;

const styles = StyleSheet.create({
  link: {
    ...fonts.a,
    cursor: 'pointer',
    margin: 12,
    color: whiteAt98,
    textDecoration: 'underline',
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      marginTop: 0
    }
  }
});

function AnchorLink({ linkTarget }) {
  const eventName = `science_${linkTarget.replace('-', '_')}`;

  // ignore advice against using anchor as button because buttons come with browser styling that we would have to override
  // avoid adding tabIndex (focus support) to element because it interrupts the slideTo animation
  return (
    /* eslint-disable jsx-a11y/anchor-is-valid */
    /* eslint-disable jsx-a11y/interactive-supports-focus */
    <a
      className={css(styles.link)}
      data-track
      data-track-location="body"
      data-track-link-click={eventName}
      onClick={() => slideToAnchor(linkTarget, headerOffset)}
      onKeyUp={() => slideToAnchor(linkTarget, headerOffset)}
      role="button"
    >
      {i18n.t(`SciencePage.EclipseHero.links.${linkTarget}`)}
    </a>
  );
}

AnchorLink.propTypes = {
  linkTarget: PropTypes.string.isRequired
};

export default AnchorLink;
